@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: "Exo 2", sans-serif;
  font-size: 1.2em;
  background-color: #000;
  color: #a4ff8f;
}

.centipede-enhanced {
  text-align: center;
  padding: 20px;
  position: relative;
  min-height: 100vh;
  background-color: black;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #111;
  color: lime;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.navbar ul li {
  cursor: pointer;
}

.navbar .cta-button {
  padding: 10px 20px;
  background-color: lime;
  color: black;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

/* Hero Section */
.hero-section {
  padding: 40px 20px;
  color: #a4ff8f;
  position: relative;
  text-align: center;
}

.hero-section h1 {
  font-size: 2.2em; /* Adjusted for smaller screens */
  margin-bottom: 10px;
  line-height: 1.2;
}

.hero-section .tagline {
  font-size: 1em;
  margin-bottom: 20px;
}

.hero-section .cta-button {
  margin-top: 20px;
}

/* Responsive Hero Background */
.centipede-enhanced::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(./main_bck.png);
  background-position: center;
  background-size: cover;
  opacity: 0.2;
  z-index: 0;
}

/* Features Section */
.icon-section, .detailed-section, .premium-section, .testimonials {
  padding: 20px;
}

.icon-card-container, .detailed-card-container, .premium-card-container, .testimonial-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.icon-card, .detailed-card {
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid lime;
  border-radius: 8px;
  padding: 15px;
  width: 90%; /* Default for mobile */
  max-width: 300px;
  text-align: center;
  color: #b2ffb5;
  box-shadow: 0 5px 10px rgba(0, 255, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}

.icon-card:hover, .detailed-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 255, 0, 0.5);
}

.icon-card .icon {
  font-size: 2em; /* Reduced for better fit on mobile */
  margin-bottom: 10px;
}

/* Testimonials */
.testimonial {
  font-style: italic;
  color: #90ee90;
  width: 90%; /* Default for mobile */
  max-width: 300px;
}

/* Footer */
.footer {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #90ee90;
}

.footer .cta-button {
  margin-top: 20px;
}

/* Links */
a {
  color: lime;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  body {
    font-size: 1em;
  }

  .hero-section h1 {
    font-size: 1.8em;
  }

  .hero-section .tagline {
    font-size: 0.9em;
  }

  .navbar ul {
    flex-direction: column;
    gap: 10px;
  }

  .icon-card, .detailed-card, .testimonial {
    width: 95%;
  }

  .navbar .cta-button {
    font-size: 0.9em;
    padding: 8px 15px;
  }
}
